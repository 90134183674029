<template>
  <v-card>
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-forward</v-icon>
      </template>
    </v-breadcrumbs>
    <h4 class="text-center py-4">{{ TypeName }}</h4>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="isAdmin"
          color="primary"
          class=".float-left ml-20"
          dark
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-briefcase-plus-outline</v-icon>
          Add system
        </v-btn>
      </template>
      <v-form @submit.prevent="createSystem">
        <v-card>
          <v-card-title>
            <span class="headline">{{ systemObj.name || 'System' }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="System name*"
                    :error-messages="nameErrors"
                    v-model="systemObj.name"
                    required
                    @blur="$v.systemObj.name.$touch()"
                    >{{ systemObj.name }}</v-text-field
                  >
                </v-col>
                <!-- <v-col cols="12" sm="12" md="6">
                  <v-select
                    :items="SYSTEM_NAMES_ENUM"
                    :error-messages="systemTypeErrors"
                    v-model="systemObj.system_type"
                    label="System type"
                    item-text="name"
                    item-value="code"
                    persistent-hint
                    return-object
                    single-line
                    required
                  ></v-select>
                </v-col> -->
                <v-col cols="12">
                  <v-text-field
                    label="System URL"
                    :error-messages="urlErrors"
                    v-model="systemObj.url"
                    required
                    >{{ systemObj.url }}</v-text-field
                  >
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="System Operation URL"
                    :error-messages="operatioUrlErrors"
                    v-model="systemObj.operation_url"
                    required
                    >{{ systemObj.operation_url }}</v-text-field
                  >
                </v-col>
                <v-col cols="12">
                  <v-combobox
                    label="Smart code*"
                    v-model="systemObj.smart_codes"
                    name="smart_codes"
                    :filter="filter"
                    :items="systemObj.smart_codes"
                    :error-messages="systemCodesErrors"
                    hide-selected
                    multiple
                    small-chips
                    solo
                    required
                  >
                  </v-combobox>
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :disabled="$v.anyError"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search (must be > 4 characters)"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="total"
      :search="search"
      :footer-props="{
        'items-per-page-options': itemsPerPage
      }"
      class="elevation-1 text-center"
    >
      <template v-slot:item.name="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="info lighten-1"
              v-bind="attrs"
              v-on="on"
              width="100%"
              @click="toSystemPage(item)"
            >
              {{ item.name
              }}<v-icon color="orange" text>mdi-vector-link</v-icon></v-btn
            >
          </template>
          <span>{{ item.name }} system page</span>
        </v-tooltip>
      </template>
      <template v-slot:item.token="{ item }">
        <v-tooltip v-if="isAdmin" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="checkClientSettings(item)"
              color="info lighten-1"
              class="margin-rigth-5"
              ><v-icon>
                mdi-table-key
              </v-icon></v-btn
            >
          </template>
          <span>check client settings</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="info lighten-1"
              v-bind="attrs"
              v-on="on"
              :href="item.url"
              target="_blank"
              icon
            >
              <v-icon color="orange" text>mdi-open-in-new</v-icon></v-btn
            >
          </template>
          <span>to integration {{ item.name }} admin</span>
        </v-tooltip>
      </template>
      <template v-slot:item.manage="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              color="info"
              v-bind="attrs"
              class="button"
              v-on="on"
              @click="changeSystem(item, 'integration')"
            >
              <v-icon>mdi-microsoft-xbox-controller-menu</v-icon>
              integrations</v-btn
            >
          </template>
          <span>to integrations</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              color="primary"
              v-bind="attrs"
              class="button"
              v-on="on"
              @click="changeSystem(item, 'event')"
            >
              <v-icon>mdi-calendar-star</v-icon>
              events</v-btn
            >
          </template>
          <span>to events</span>
        </v-tooltip>
        <v-tooltip bottom v-if="isAdmin">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              dark
              color="red lighten-1"
              class="button"
              v-bind="attrs"
              v-on="on"
              @click="changeSystem(item, 'error')"
              ><v-icon>mdi-alert-octagram</v-icon> errors</v-btn
            >
          </template>
          <span>to errors</span>
        </v-tooltip>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              class="margin-rigth-5"
              @click="runSync(item._id, item.smart_codes)"
              ><v-icon color="green"> mdi-database-sync </v-icon></v-btn
            >
          </template>
          <span>run synchronisation</span>
        </v-tooltip>

        <v-tooltip v-if="isAdmin" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="getSystem(item._id)"
              class="margin-rigth-5"
              ><v-icon color="teal"> mdi-pencil </v-icon></v-btn
            >
          </template>
          <span>edit</span>
        </v-tooltip>
        <v-tooltip v-if="isAdmin" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="deleteItem(item)"
              color="red darken-2"
              class="margin-rigth-5"
              ><v-icon>
                mdi-delete
              </v-icon></v-btn
            >
          </template>
          <span>delete</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline"
          >Are you sure you want to delete -
          <b>'{{ deletedItemName }}'</b> system?</v-card-title
        >
        <v-card-actions>
          <v-btn color="blue darken-1" dark @click="closeDelete">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red lighten-1" dark @click="deleteItemConfirm"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogClientSettings"
      scrollable
      max-width="524px"
      max-higth="800px"
    >
      <v-card>
        <v-card-title
          >Client settings for {{ clientSettings.name }}</v-card-title
        >
        <v-divider></v-divider>
        <v-card-text style="height: 100px;">
          <p>
            System_id: <b>{{ clientSettings._id }}</b>
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text style="height: 120px;">
          <p>
            Token: <b>{{ clientSettings.token }}</b>
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogClientSettings = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import store from '@/store/index'
import SystemService from '@/services/SystemService.js'
import { paginationMixin } from '@/mixins/PaginationMixin.js'
import { SystemMixin } from '@/mixins/SystemMixin.js'

export default {
  mixins: [paginationMixin, SystemMixin],
  props: {
    TypeName: {
      type: String,
      default: ''
    }
  },
  watch: {
    model(val, prev) {
      if (val.length === prev.length) return

      this.model = val.map(v => {
        if (typeof v === 'string') {
          this.systemObj.smart_codes.push(v)

          this.nonce++
        }

        return v
      })
    }
  },
  data() {
    var r = /^(ftp|http|https):\/\/[^ "]+$/
    return {
      dialog: false,
      dialogClientSettings: false,
      clientSettings: {},
      systemObj: this.createFreshSystemObject(),
      SYSTEM_NAMES_ENUM: [
        { code: 'adv', name: 'Реклама' },
        { code: 'appeal', name: 'Обращения' },
        { code: 'crm', name: 'CRM' },
        { code: 'analytics', name: 'Аналитика' },
        { code: 'email', name: 'EMAIL-маркетинг' },
        { code: 'classifide', name: 'Классифайды' },
        { code: 'monitoring', name: 'Мониторинг СМИ и соц. сетей' },
        { code: 'source', name: 'Источники данных' },
        { code: 'other', name: 'Другое' }
      ]
    }
  },
  created() {
    this.setBreadcrumbs([
      { text: 'Home', to: { path: '/' } },
      {
        text: ':systems'
      }
    ])
    this.replaceBreadcrumb({
      find: ':systems',
      replace: {
        text: `${this.TypeName}`,
        to: {
          name: 'systemsByType',
          params: { system_type: this.systemType }
        },
        disabled: true
      }
    })
  },
  methods: {
    toSystemPage(item) {
      this.$store.dispatch('systems/setSystem', {
        systemId: item._id,
        systemName: item.name,
        systemType: this.$route.params.system_type,
        systemTypeName: this.TypeName,
        systemUrl: item.url
      })
      this.$router.push({ name: 'SystemPage', params: { system_id: item._id } })
    },
    changeSystem(item, link) {
      console.log('change')
      this.$store.dispatch('systems/setSystem', {
        systemId: item._id,
        systemName: item.name,
        systemType: this.$route.params.system_type,
        systemTypeName: this.TypeName,
        systemUrl: item.url
      })
      if (link === 'error') {
        this.$router.push({
          name: 'systemErrors',
          params: {
            system_id: item._id
          }
        })
      } else if (link === 'event') {
        this.$router.push({
          name: 'systemEvents',
          params: {
            system_id: item._id,
            systemName: item.name
          }
        })
      } else {
        this.$router.push({
          name: 'systemIntegrations',
          params: {
            system_id: item._id,
            systemName: item.name
          }
        })
      }
    },
    getDataFromAPI(offset) {
      // console.log(this.options)
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        let items = []
        let total = 0
        let typeName = ''
        let sort_by = sortBy.length == 1 ? sortBy[0] : '_id'
        let sort = sortDesc.length && sortDesc[0] == 1 ? -1 : 1
        offset = offset !== 0 ? itemsPerPage * (page - 1) : 0
        SystemService.getSystemsByType(
          this.systemType,
          itemsPerPage,
          offset || 0,
          sort_by,
          sort,
          this.search
        )
          .then(response => {
            items = response.data.results
            total = response.data.count || 0
            typeName = response.data.type_name
            resolve({ items, total, typeName })
          })
          .catch(error => {
            console.log(error)
            store.dispatch('setLoader', false)
          })
      })
    },
    checkClientSettings(item) {
      this.dialogClientSettings = true
      this.clientSettings = item
    },
    deleteItemConfirm() {
      const index = this.items.indexOf(this.DeleteItem)
      SystemService.deleteSystem(this.DeleteItem._id).then(() => {
        this.items.splice(index, 1)
        this.closeDelete()
        const notification = {
          type: 'success',
          message: `System: ${this.DeleteItem.name} deleted.`
        }
        this.$store.dispatch('notification/add', notification, { root: true })
        this.$store.dispatch('error/getTotalNotCheckedErrors')
        this.getDataFromAPI(this.itemType, 0).then(data => {
          // console.log(data.items)
          this.items = data.items
          this.total = data.total
        })
      })
    },
  },
  computed: {
    nameErrors() {
      const errors = []
      if (!this.$v.systemObj.name.$dirty) return errors
      !this.$v.systemObj.name.required && errors.push('Name is required.')
      return errors
    },
    urlErrors() {
      const errors = []
      if (!this.$v.systemObj.url.$dirty) return errors
      !this.$v.systemObj.url.required && errors.push('URl is required.')
      !this.$v.systemObj.url.url && errors.push('invalid url')
      return errors
    },
    operatioUrlErrors() {
      const errors = []
      if (!this.$v.systemObj.operation_url.$dirty) return errors
      !this.$v.systemObj.operation_url.url && errors.push('invalid url')
      return errors
    },
    // systemTypeErrors() {
    //   const errors = []
    //   if (!this.$v.systemObj.system_type.$dirty) return errors
    //   !this.$v.systemObj.system_type.required &&
    //     errors.push('System Type is required.')
    //   return errors
    // },
    systemCodesErrors() {
      const errors = []
      if (!this.$v.systemObj.system_codes.$dirty) return errors
      console.log(this.systemObj.smart_codes.length)
      if (this.systemObj.smart_codes.length === 0) {
        errors.push('System codes is required')
      }
      return errors
    },
    systemType() {
      return this.$route.params.system_type
    },
    isAdmin() {
      return this.$store.getters['auth/isAdmin']
    },
    headers() {
      let headers = [
        {
          text: 'Name',
          align: 'center',
          filterable: false,
          value: 'name'
        },
        // { text: 'Integration url', value: 'url' },
        { text: 'Integrations codes', value: 'smart_codes' },
        { text: 'Events count', value: 'events_count' },
        { text: 'Errors count', value: 'errors_count' },
        { text: 'Integration count', value: 'integration_count' },
        { text: 'Manage', value: 'manage', sortable: false },
        { text: 'Actions', value: 'action', sortable: false }
      ]
      if (this.isAdmin) {
        headers.push({
          text: 'Client Settings',
          value: 'token',
          sortable: false
        })
      }
      return headers
    }
  }
}
</script>

<style scoped>
.v-btn.v-size--small {
  font-size: 0.65em;
  margin-left: 2px;
}
.margin-rigth-5 {
  margin-right: 5px !important;
}
.ml-20 {
  margin-left: 20px;
}
.button {
  display: inline-flex;
}
@media (max-width: 1380px) {
  .button {
    display: flex;
    margin-top: 5px;
  }
}
</style>
